import React, { useEffect, useRef, useState } from "react";
import { Autoplay, Navigation, Swiper as SwiperType } from "swiper";
import { SwiperSlide, Swiper } from "swiper/react";
import styles from "./BannerSwiper.module.scss";
import cn from "classnames";

import IconArrowRight from "public/img/icon-arrow-right.svg";
import AcceptBanner from "./AcceptBanner/AcceptBanner";
import { Button } from "components/shared/Button/Button";
import { useTranslation } from "next-i18next";
import { Modal } from "components/shared/Modal/ModalWindow";
import ContactUsLanding from "components/shared/ContactUsLanding/ContactUsLanding";
import { MercuryoBanner } from "./MercuryoBanner/MercuryoBanner";
import CRMSBanner from "./CRMSBanner";
import AppBanner from "./AppBanner";
import CRMSMintedBanner from "./CRMSMintedBanner";
import { useRouter } from "next/router";
import CRMSCommunity from "./CRMSCommunity/CRMSCommunity";
import { CRMSForumsBanner } from "./CRMSForumsBanner";
import { getLocaleLink } from "../../../utils/getLocaleLink";
import RedesignBanner from "./RedesignBanner/RedesignBanner";
import { RaisingAPRBunner } from "./RaisingAPRBunner/RaisingAPRBunner";

interface IBannerItem {
  banner: JSX.Element;
}

const BannerSwiper = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isModalActice, setIsModalActive] = useState<boolean>(false);
  const swiperRef = useRef<SwiperType | null>(null);
  const { t } = useTranslation("common");
  const router = useRouter();
  const { locale } = router;

  const banners: IBannerItem[] = [
    {
      banner: <RaisingAPRBunner />,
    },
    {
      banner: <RedesignBanner />,
    },
    {
      banner: <CRMSMintedBanner />,
    },
    {
      banner: <AppBanner />,
    },
    {
      banner: <CRMSBanner />,
    },
    {
      banner: <MercuryoBanner />,
    },
    {
      banner: <AcceptBanner setIsModalActive={setIsModalActive} />,
    },
  ];

  const [bannerList, setBannerList] = useState<IBannerItem[]>(banners);

  useEffect(() => {
    if (locale === "ru") {
      setBannerList([
        ...bannerList,
        {
          banner: <CRMSCommunity />,
        },
      ]);
    } else if (locale === "en") {
      setBannerList([
        ...bannerList,
        {
          banner: <CRMSForumsBanner />,
        },
      ]);
    } else {
      setBannerList([...banners]);
    }
  }, [locale]);

  const updateSwiper = () => swiperRef.current?.update();

  useEffect(() => {
    window?.addEventListener("resize", updateSwiper);

    return () => {
      window?.addEventListener("resize", updateSwiper);
    };
  }, []);

  return (
    <>
      <div className={styles.swiper__wrapper}>
        <button
          onClick={() => swiperRef.current?.slidePrev()}
          className={cn(styles.swiper_button, styles.back_button)}
          aria-label={"Swiper banner prev"}
        >
          <IconArrowRight />
        </button>
        <button
          onClick={() => swiperRef.current?.slideNext()}
          className={cn(styles.swiper_button, styles.forward_button)}
          aria-label={"Swiper banner next"}
        >
          <IconArrowRight />
        </button>
        <Swiper
          autoplay={{ delay: activeIndex === 0 ? 11000 : 7000, disableOnInteraction: true }}
          dir="ltr"
          direction="horizontal"
          slidesPerView={1}
          onActiveIndexChange={({ realIndex }) => {
            setActiveIndex(realIndex);
          }}
          modules={[Navigation, Autoplay]}
          loop={true}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          className={cn(styles.swiper_list, { [styles.CPT]: activeIndex === 1 })}
          breakpoints={{ 1024: { threshold: 20 }, 0: { threshold: 0 } }}
        >
          {bannerList.map((item, i) => (
            <SwiperSlide className={styles.swiper_item} key={i}>
              {item.banner}
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={styles.banner__btns}>
          {activeIndex === 4 && (
            <>
              <Button
                appearance="primary"
                size="xxl"
                white
                fullWidth
                linkProps={{
                  href: getLocaleLink("https://app.cryptomus.com/signup", locale),
                  type: "external",
                }}
              >
                {t("Sign Up")}
              </Button>
              <Button
                appearance="secondary"
                size="xxl"
                white
                fullWidth
                onClick={() => setIsModalActive(true)}
              >
                {t("Contact us")}
              </Button>
            </>
          )}
        </div>

        <div className={styles.progress_bar}>
          {bannerList.map((item, i) => (
            <span
              key={i}
              className={cn(styles.progress_point, { [styles.active]: activeIndex === i })}
            />
          ))}
        </div>
      </div>
      <Modal isActive={isModalActice} setIsActive={setIsModalActive}>
        <ContactUsLanding size="xl" modal onSubmit={() => setIsModalActive(false)} />
      </Modal>
    </>
  );
};

export default BannerSwiper;
